export class Sidebar {
    B2C: MenuItem = new MenuItem();
    B2B: MenuItem = new MenuItem();
    Sales: MenuItem = new MenuItem();
    [key: string]: MenuItem;
}

export class MenuItem {
    SupCat: string;
    SupCatId: number;
    isShow: boolean;
    list: MenuItemList[];
}

export class MenuItemList {
    groupName: string;
    groupId: number;
    catList: MenuItemCatList[];
}

export class MenuItemCatList {
    catName: string;
    catId: number;
    menuIcon:string;
    isShow: boolean;
    menuList: MenuList[]
}

export class MenuList {
    subCat: string;
    menuIcon:string;
    routerLink: string;
    subCatId: number;
}
