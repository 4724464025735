export class UserDetails {
  uname: string;
  email: string;
  phone: number;
  roleName: string;
  hashValue: string;
  createdDate: Date;
}

export class User {
  userId: number;
  userName: string;
  phone: number;
  email: string;
  activestatus: boolean;
  creadtedDate: Date;
  spent: number;
  totalOrders: number;
}