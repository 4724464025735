import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { UserService } from '../service/user.service';
import { Page } from '../model/page.model';
import { StorageService } from '../service/storage.service';
import {
  EMAIL_REGEX,
  GUEST_CART_ID,
  GUEST_ID,
  PHONE_REGEX,
  REGISTRATION_COMPLETE,
  SIGNUP_EMAIL,
  SIGNUP_MOBILE,
  TEMP_TOKEN,
  TOKEN,
  USER_ID,
} from '../constants/constant';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StateService } from '../service/state.service';
import { Location } from '@angular/common';
import { timer, take, Subscription, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.less'],
})
export class SignUpComponent implements OnInit, OnDestroy {
  isMobile: boolean;
  isCheckout: boolean = false;
  tick = 1000;
  countDown: Subscription;
  counter = 30;
  unsubscribe$ = new Subject();
  signUpMobile = this.localService.getItem(SIGNUP_MOBILE);
  signUpEmail = this.localService.getItem(SIGNUP_EMAIL);
  constructor(
    private userService: UserService,
    private localService: StorageService,
    private route: Router,
    private toastr: ToastrService,
    private stateService: StateService,
    private _location: Location,
    public activatedRoute: ActivatedRoute
  ) {
    this.isMobile = !!this.signUpMobile;
    this.activatedRoute.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe(params => {
      if (params['checkout']) {
        this.isCheckout = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.countDown && this.countDown.unsubscribe();
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  signupForm = new UntypedFormGroup({
    fullName: new UntypedFormControl('', [Validators.required,
    Validators.pattern(/^[a-zA-Z\s]+$/)]),
    email: new UntypedFormControl(''),
    mobile: new UntypedFormControl('')
  });

  otpForm = new UntypedFormGroup({
    otp: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
    ]),
  });
  otpErrorMessage = '';
  errorMessage = '';
  isSignUpPage = true;
  temp_Token = this.localService.getItem(TEMP_TOKEN);
  guestCartId = this.localService.getItem(GUEST_CART_ID);
  page: Page = { pageNo: 1, pageSize: 20 };
  isApiHit: boolean = false

  ngOnInit() {
    if (this.isMobile) {
      this.signupForm.controls['email'].setValidators([
        Validators.required,
        Validators.pattern(EMAIL_REGEX)
      ])
    } else {
      this.signupForm.controls['mobile'].setValidators([Validators.required, Validators.pattern(PHONE_REGEX)])
    }
  }
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }

  resendOtp() {
    if (this.isMobile) {
      this.userService
        .checkEmailSendOtp({ email: this.signupForm.value.email })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (res) => {
            if (res.meta.status_code == 0) {
              this.toastr.error(res.meta.status_message);
            } else {
              this.toastr.info(res?.meta?.status_message);
            }
          },
          error: (err) => {
            this.toastr.error('Something Went Wrong');
          },
        });
    } else {
      this.userService
        .checkPhoneSendOtp({ phone: this.signupForm.value.mobile })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (res) => {
            if (res.meta.status_code == 0) {
              this.toastr.error(res.meta.status_message);
            } else {
              this.toastr.info(res?.meta?.status_message);
            }
          },
          error: (err) => {
            this.toastr.error('Something Went Wrong');
          },
        });
    }
    this.counter = 30
    this.changeCounterState();
  }

  onChange(e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (!this.isSignUpPage)
      this.isSignUpPage = true;
    else
      this._location.back();
  }

  removeNumberInvalidChars(event: any) {
    ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault();
  }

  get formControl(): any {
    return this.signupForm.controls;
  }

  onSubmit(formData: UntypedFormGroup, event?: any): void {

    event?.stopPropagation();
    event?.preventDefault();
    if (this.isSignUpPage) {
      if (formData.invalid) {
        this.errorMessage = "error";
      } else {
        this.isApiHit = true
        if (this.isMobile) {
          this.userService
            .checkEmailSendOtp({ email: this.signupForm.value.email })
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: (res) => {
                if (res.meta.status_code) {
                  this.isSignUpPage = !this.isSignUpPage;
                  this.changeCounterState();
                  this.toastr.info(res?.meta?.status_message);
                } else
                  this.toastr.error(res.meta.status_message);
                this.isApiHit = false
              },
              error: (err) => {
                this.toastr.error('Something Went Wrong');
              },
            });
        } else {
          this.userService
            .checkPhoneSendOtp({ phone: this.signupForm.value.mobile.toString() })
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
              next: (res) => {
                if (res.meta.status_code) {
                  this.isSignUpPage = !this.isSignUpPage;
                  this.changeCounterState();
                  this.toastr.info(res?.meta?.status_message);
                } else
                  this.toastr.error(res.meta.status_message);
                this.isApiHit = false

              },
              error: (err) => {
                this.toastr.error('Something Went Wrong');
              },
            });
        }
      }

    }
    else if (this.otpForm.invalid) this.otpErrorMessage = 'Please enter OTP.';
    else {
      this.otpErrorMessage = '';
      this.userService
        .signUp({
          fullName: this.signupForm.value.fullName,
          email: this.signupForm.value.email,
          phone: this.signupForm.value.mobile.toString(),
          isEmail: !this.isMobile,
          otp: this.otpForm.value.otp.toString(),
          guestCartId: Number(this.guestCartId),
          token: this.temp_Token,
        })
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe({
          next: (res) => {
            if (res.meta.status_code) {
              this.localService.removeItem(TEMP_TOKEN);
              this.localService.removeItem(GUEST_CART_ID);
              this.localService.removeItem(GUEST_ID);
              this.localService.setItem(
                USER_ID,
                res.data[0].UserId.toString()
              );
              this.localService.setItem(TOKEN, res.data[0].token);
              this.localService.setItem(REGISTRATION_COMPLETE, 'true');
              this.stateService.isTokenFetch(true);
              this.toastr.info(res['meta']['status_message']);
              if (this.isCheckout) {
                this.route.navigate(['user', 'checkout']);
              } else {
                this.route.navigate(['shop']);
              }
            } else if (
              res.meta.status_message ==
              'This EmailID already associated with other user'
            ) {
              this.toastr.error(res['meta']['status_message']);
              this.isSignUpPage = true;
            } else if (res['meta']['status_message'] == 'Invalid OTP') {
              this.otpErrorMessage = 'Invalid OTP.';
            }
          },
          error: (err) => {
            this.toastr.error('Something Went Wrong');
          },
        });
    }
  }

  changeCounterState(): void {
    this.countDown = timer(0, this.tick)
      .pipe(take(this.counter))
      .subscribe(() => {
        --this.counter;
        if (this.counter == 0) {
          this.countDown.unsubscribe();
        }
      });
  }
}
