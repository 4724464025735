export class Cart {
    discountPrice: number;
    imageName: string;
    mrp: number;
    pname: string;
    quantity: number;
    skuId: number;
    catName: string;
    productId: number;
    slug: string;
}

export class CartTotal {
    DiscountPriceTotal: string;
    MrpTotal: string;
}

export class CartItem {
    skuId: string;
    quantity?: number;
    flag?: number;
}