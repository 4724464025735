import { NgModule, ErrorHandler } from '@angular/core';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SignUpComponent } from './sign-up/sign-up.component';

import { UserService } from './service/user.service';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule, ToastrService } from 'ngx-toastr';
import { TokenInterceptor } from './interceptor/token-interceptor.interceptor';
import { StorageService } from './service/storage.service';
import { GlobalErrorHandler } from './service/global-error-handler.service';
import { StateService } from './service/state.service';
import { Router, UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializerService } from './service/lower-case-url-serializer.service';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoRootModule } from './service/transloco-http-loader';
@NgModule({
  declarations: [AppComponent, SignUpComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslocoRootModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      progressBar: true,
      progressAnimation: 'decreasing',
      preventDuplicates: true,
      positionClass: 'toast-bottom-left',
    }),
  ],
  providers: [
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
      deps: [StorageService, ToastrService, StateService, Router],
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializerService },
    TranslocoRootModule
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
