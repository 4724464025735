import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, catchError, map } from 'rxjs';
import { StorageService } from '../service/storage.service';
import { ADMIN_TOKEN, TOKEN, USER_ID } from '../constants/constant';
import { ToastrService } from 'ngx-toastr';
import { StateService } from '../service/state.service';
import { Data } from '../model/data.model';
import { Router } from '@angular/router';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  secretKey: string = "QfTjWnZq4t7w!z%C*F-JaNdRgUkXp2s5";

  constructor(private localStorage: StorageService,
    private toastr: ToastrService,
    private stateService: StateService,
    private router: Router) { }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let jwt = httpRequest.url.includes("Admin") || httpRequest.url.includes("admin") ? this.localStorage.getItem(ADMIN_TOKEN) : this.localStorage.getItem(TOKEN);
    const headers: any = {};

    if (jwt) {
      headers['authorization'] = `Bearer ${jwt}`;
    }

    // const encryptedPayload = this.encryption(httpRequest.body)
    const encryptedRequest = httpRequest.clone({
      // body: { request_data: encryptedPayload },
      setHeaders: headers
    });

    return next.handle(encryptedRequest).pipe(map((event: HttpEvent<any>) => {
      let updatedEvent: any;
      if (event instanceof HttpResponse) {

        // const decryptedPayload = this.decrypt(event.body.response_data)
        // updatedEvent = { ...event, body: decryptedPayload }

        if (event.status == 200) {
          let message = event.body?.meta?.status_message;
          if (message == 'Auth Expire') {
            this.localStorage.removeItem(ADMIN_TOKEN);
            this.toastr.info("Token expired. Please Log In again.")
            this.router.navigate(['/admin/login'])
          } else if (message == "User is not login" || message == "You are not login") {
            this.localStorage.removeItem(TOKEN);
            this.localStorage.removeItem(USER_ID);
            this.toastr.info("Token expired. Please Log In again.")
            this.router.navigate([''])
          }
        }
      }
      return event;
    }),
      catchError((error: HttpErrorResponse) => {

        // const decryptedPayload = this.decrypt(error.error.response_data)
        // const data: Data<any> = decryptedPayload;

        const data: Data<any> = error.error;
        if (error.status == 401) {
          if (this.router.url.includes('admin'))
            this.router.navigate(['/admin/login'])
          else
            this.router.navigate([''])
          this.toastr.info("Please Log In again.")
          this.localStorage.removeItem(TOKEN)
          this.localStorage.removeItem(ADMIN_TOKEN)
          this.localStorage.removeItem(USER_ID)
          this.stateService.reloadGuestUser(true);
          this.stateService.isTokenFetch(true);
          this.stateService.sendPrivilegesMenu([])
        }
        if (data?.meta)
          this.toastr.error(data.meta.status_message)

        else if (error.status === 0)
          this.toastr.error('Request could not be completed.');

        else
          this.toastr.error(error.statusText)
        this.stateService.shimmerLoaderUpdate(false);
        return '';
      }));
  }

  decrypt(payload: any): any {
    const key = CryptoJS.enc.Utf8.parse(this.secretKey);
    const decrypted = CryptoJS.AES.decrypt(payload, key, {
      mode: CryptoJS.mode.ECB,
      keySize: 256 / 8,
    });

    const decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedString);
  }

  encryption(payload: any): any {
    const payloadString = JSON.stringify(payload);
    const key = CryptoJS.enc.Utf8.parse(this.secretKey);
    const encrypted = CryptoJS.AES.encrypt(payloadString, key, {
      mode: CryptoJS.mode.ECB,
      keySize: 256 / 8,
    });
    return encrypted.toString();
  }
}
