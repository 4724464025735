import { inject } from "@angular/core";
import { CanActivateFn, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { TEMP_TOKEN } from "../constants/constant";
import { StorageService } from "../service/storage.service";

export const SignInGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  const localStorage = inject(StorageService)
  const router = inject(Router)
  if (localStorage.getItem(TEMP_TOKEN)) {
    return true;
  }
  router.navigate(['']);
  return false;
}
