<div class="signWrp">
    <div class="row m-0">
        <div class="col-6 signBg">

        </div>
        <div class="col">
            <div class="signSub">
                <div>
                    <div *ngIf="isSignUpPage">
                        <h1>Almost there</h1>
                        <p>Let us know more about you!</p>
                    </div>
                    <div class="signForm">
                        <form [formGroup]="signupForm" (ngSubmit)="onSubmit(signupForm)">
                            <div *ngIf="isSignUpPage">
                                <div class="loginDetails">
                                    <h5>{{isMobile ? signUpMobile : signUpEmail}}</h5>
                                    <button (click)="onChange($event)">Change</button>
                                </div>
                                <input type="text" placeholder="Full Name*" formControlName="fullName" appInputText
                                    (keydown.enter)="onSubmit(signupForm, $event)">
                                <div *ngIf="errorMessage!='' && formControl['fullName'].invalid"
                                    class="alert alert-danger">
                                    <div *ngIf="formControl['fullName'].errors.required">
                                        Name is Required.
                                    </div>
                                    <div *ngIf="formControl['fullName'].errors.pattern">
                                        Name cannot have numbers or special characters
                                    </div>
                                </div>
                                <div *ngIf="isMobile">
                                    <input type="text" placeholder="Email*" formControlName="email" appInputEmail
                                        (keydown.enter)="onSubmit(signupForm, $event)" />
                                    <div *ngIf="errorMessage!='' && formControl['email'].invalid"
                                        class="alert alert-danger">
                                        <div *ngIf="formControl['email'].errors.required">
                                            Email is Required.
                                        </div>

                                        <div *ngIf="formControl['email'].errors.pattern">
                                            Email is Invalid.
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!isMobile">
                                    <input type="text" placeholder="Mobile Number*" formControlName="mobile"
                                        minlength="10" maxlength="10" appInputNumber
                                        (keydown.enter)="onSubmit(signupForm, $event)" />
                                    <div *ngIf="errorMessage!='' && formControl['mobile'].invalid"
                                        class="alert alert-danger">
                                        <div *ngIf="formControl['mobile'].errors.required">
                                            Mobile Number is Required.
                                        </div>

                                        <div *ngIf="formControl['mobile'].errors.pattern">
                                            Mobile Number is Invalid.
                                        </div>
                                    </div>
                                </div>

                                <button class="btn btn-danger" type="submit" [disabled]="isApiHit">
                                    Continue
                                    <span *ngIf="isApiHit" class="spinner-border spinner-border-sm" role="status"
                                        aria-hidden="true"></span>
                                </button>
                            </div>
                        </form>

                        <form [formGroup]="otpForm" (ngSubmit)="onSubmit(otpForm)">
                            <div *ngIf="!isSignUpPage">
                                <h1>Verify {{isMobile?'email':'mobile'}}</h1>
                                <p>Please check your {{isMobile?'email':'mobile'}} for the One-Time Password (OTP) to
                                    complete your registration
                                </p>
                                <div class="loginDetails">
                                    <h5>{{isMobile ? signupForm.value.email : signupForm.value.mobile}}</h5>
                                    <button (click)="onChange($event)">Change</button>
                                </div>

                                <div class="signForm">
                                    <input type="text" placeholder="Enter OTP*" formControlName="otp" appInputNumber
                                        (keyup)="removeNumberInvalidChars($event)" (keyup.enter)="onSubmit(otpForm)"
                                        minlength="6" maxlength="6" autofocus />
                                    <div id="MsgErrorFtr" class="alert alert-danger mb-2" *ngIf="otpErrorMessage!=''">
                                        {{otpErrorMessage}}</div>
                                    <input type="submit" value="Verify OTP" />
                                </div>
                                <div class="alAccount">
                                    Didn't receive OTP? <button aria-label="resend otp" type="button" class="resendOtp"
                                        (click)="resendOtp()" [disabled]="counter!=0">Resend</button>
                                    <div class="otpcountDown" style="font-size: 16px;" *ngIf="counter">
                                        {{transform(counter)}}</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>